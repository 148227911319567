import moment from 'moment'
import React, {FC} from 'react'

type Props = {
  phone?: string
}

const UserLocationCell: FC<Props> = ({phone}) => {
  const date = new Date(Number(phone || ''))

  return <div className='badge badge-light fw-bolder'>{phone ? date.toDateString() : 'N/A'}</div>
}

export default UserLocationCell
