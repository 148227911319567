// MediaViewer.jsx
import React, {useState} from 'react'

interface MediaViewerInterface {
  media: any[]
  isOpen: boolean
  onClose: () => void
}
const MediaViewer: React.FC<MediaViewerInterface> = ({media, isOpen, onClose}) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  if (!isOpen || !media.length) return null

  const currentMedia = media[currentIndex]
  const isImage = currentMedia?.type === 'image'

  const goToPrevious = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : media.length - 1))
  }

  const goToNext = () => {
    setCurrentIndex((prev) => (prev < media.length - 1 ? prev + 1 : 0))
  }

  return (
    <>
      <div className='modal d-block'>
        <div className='modal-dialog modal-fullscreen'>
          <div className='modal-content bg-dark'>
            {/* Header */}
            <div className='modal-header border-0 bg-dark'>
              <div className='d-flex align-items-center text-white'>
                <button className='btn-close btn-close-white me-3' onClick={onClose}></button>
                <div className='media-info'>
                  <h6 className='mb-0'>
                    Media {currentIndex + 1} of {media.length}
                  </h6>
                </div>
              </div>
            </div>

            {/* Body */}
            <div className='modal-body d-flex align-items-center justify-content-center position-relative p-0'>
              {media.length > 1 && (
                <>
                  <button
                    className='btn btn-light rounded-circle position-absolute start-3'
                    onClick={goToPrevious}
                  >
                    <i className='bi bi-chevron-left'></i>
                  </button>
                  <button
                    className='btn btn-light rounded-circle position-absolute end-3'
                    onClick={goToNext}
                  >
                    <i className='bi bi-chevron-right'></i>
                  </button>
                </>
              )}

              <div className='media-container text-center'>
                {isImage ? (
                  <img
                    src={currentMedia.url}
                    className='img-fluid max-height-90vh'
                    alt='Media preview'
                  />
                ) : (
                  <video src={currentMedia.url} className='max-height-90vh' controls autoPlay />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop show'></div>

      <style>
        {`
          .max-height-90vh {
            max-height: 90vh;
            width: auto;
          }
          .modal-fullscreen {
            margin: 0;
            max-width: 100%;
          }
          .start-3 {
            left: 1rem;
          }
          .end-3 {
            right: 1rem;
          }
        `}
      </style>
    </>
  )
}

export default MediaViewer
