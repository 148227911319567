import {gql} from '@apollo/client'

export const GET_SUPPORT_TICKETS = gql`
  query GetTicketsPaginated(
    $page: Int!
    $limit: Int!
    $status: String
    $category: String
    $startDate: String
    $endDate: String
    $search: String
  ) {
    getTicketsPaginated(
      page: $page
      limit: $limit
      status: $status
      category: $category
      startDate: $startDate
      endDate: $endDate
      search: $search
    ) {
      message
      status
      tickets {
        user {
          _id
          first_name
          last_name
          email
          profile_picture
        }
        videos
        updatedAt
        supportTicketID
        subject
        status
        images
        createdAt
        content
        category
        _id
      }
      totalDocs
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      prevPage
      nextPage
    }
  }
`

export const DELETE_MANY_TICKETS = gql`
  mutation DeleteManyTickets($ids: [ID!]!) {
    deleteManyTickets(ids: $ids) {
      status
      message
    }
  }
`
export const GET_TICKET_BY_ID = gql`
  query GetTicketByIDR($id: ID!) {
    getTicketByIDR(_id: $id) {
      status
      message
      ticket {
        _id
        status
        supportTicketID
        user {
          _id
          first_name
          last_name
          email
          phone_number
          profile_picture
          date_of_birth
          gender
          isBlocked
          last_seen
          can_post
          subscriptionType
        }
        content
        subject
        category
        images
        videos
        createdAt
        updatedAt
      }
    }
  }
`

export const UPDATE_TICKET_STATUS = gql`
  mutation UpdateTicketStatus($id: ID!, $status: String!) {
    updateTicketStatus(_id: $id, status: $status) {
      status
      message
    }
  }
`
