import {isImageUrl} from '../../helper/imageHelpers'

type Props = {
  post: any
}
const PostImagesView: React.FC<Props> = ({post}) => {
  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Post Images</h3>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-9'>
          <div className='d-flex justify-content-center align-items-center flex-column flex-wrap flex-sm-nowrap mb-3'>
            {/* Banner Image Container Begins */}
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <div
                  id='banner-image'
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {isImageUrl(post?.images[0]) === true ? (
                    <img
                      src={post?.images[0]}
                      alt='Post Image'
                      width={'100%'}
                      style={{maxHeight: '300px', borderRadius: '10px'}}
                    />
                  ) : <div>No Images</div>}
                </div>
              </div>
            </div>
            {/* Banner Image Container Ends */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostImagesView
