import {gql} from '@apollo/client'

export const UPDATE_USER_STATUS = gql`
  mutation UpdateUserStatus($id: String!, $status: Boolean!) {
    updateUserStatus(_id: $id, status: $status) {
      _id
      first_name
      last_name
      email
      phone_number
      profile_picture
      date_of_birth
      gender
      isBlocked
    }
  }
`

export const GET_MONTHLY_USER_ANALYTICS = gql`
  query GetMonthlyUserStatsR {
    getMonthlyUserStatsR {
      message
      status
      data {
        total
        increasePercentage
        maleCount
        femaleCount
        thisMonthFreeUsers
        iosUsersCount
        androidUsersCount
        freeUsersCount
        subscribedUsersCount
        totalAndroidMonthlySubscribedUsers
        totalIosMonthlySubscribedUsers
        totalAndroidSubscribedUsers
        totalAndroidYearlySubscribedUsers
        totalIosSubscribedUsers
        totalIosYearlySubscribedUsers
      }
    }
  }
`

export const OVERALL_USERS_ANALYTICS = gql`
  query OverAllRegisteredAnalytics($fromMonth: String, $toMonth: String) {
    overAllRegisteredAnalytics(from_month: $fromMonth, to_month: $toMonth) {
      total
      maleCount
      femaleCount
      total
      maleCount
      femaleCount
      iosUsersCount
      androidUsersCount
      freeUsersCount
      subscribedUsersCount
      totalAndroidMonthlySubscribedUsers
      totalIosMonthlySubscribedUsers
      totalAndroidSubscribedUsers
      totalAndroidYearlySubscribedUsers
      totalIosSubscribedUsers
      totalIosYearlySubscribedUsers
    }
  }
`

export const OVERALL_SUBSCRIPTIONS_ANALYTICS = gql`
  query OverAllSubscriptionsAnalytics($fromMonth: String, $toMonth: String) {
    overAllSubscriptionsAnalytics(from_month: $fromMonth, to_month: $toMonth) {
      freeUsers
      subscribedUsers
    }
  }
`

export const MONTHLY_SUBSCRIPTIONS_ANALYTICS = gql`
  query MonthlySubscriptionAnalytics {
    monthlySubscriptionAnalytics {
      freeUsers
      subscribedUsers
      increasePercentage
    }
  }
`

export const GET_ALL_USERS_DATA = gql`
  query GetAllUserData {
    getAllUserData {
      _id
      first_name
      last_name
      email
      phone_number
      profile_picture
      date_of_birth
      gender
      isBlocked
      subscriptionType
    }
  }
`

export const GET_USERS_SUBSCRIPTION_HISTORY = gql`
  query GetUserSubscriptionHistory($userId: String!) {
    getUserSubscriptionHistory(userId: $userId) {
      message
      status
      subscriptions {
        order_id
        type
        user
        auto_renewing
        acknowledged
        purchase_token
        start_date
        end_date
        platform
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_USER_DEVICES = gql`
  query GetUserDevices($userId: String!) {
    getUserDevices(userId: $userId) {
      _id
      platform
      version
      deviceModel
      createdAt
      updatedAt
    }
  }
`

export const PLATFORM_ANALYTICS = gql`
  query GetPlatformBasedSubscriptions($type: String!) {
    getPlatformBasedSubscriptions(type: $type) {
      totalAndroidCount
      totalIosCount
      totalAndroidCountM
      totalAndroidCountY
      totalIosCountM
      totalIosCountY
      totalAndroidMonthlyPlan
      totalIosMonthlyPlan
      totalAndroidYearlyPlan
      totalIosYearlyPlan
      totalActiveUsers
      activeAndroidUsersCount
      activeIosUsersCount
      allAndroidCount
      allIosCount
      allAndroidMonthlyPlan
      allIosMonthlyPlan
      allAndroidYearlyPlan
      allIosYearlyPlan
    }
  }
`
