import React, {useEffect, useState} from 'react'
import AdsDetailsViewHeader from './AdsDetailsViewHeader'
import AdsDetailsViewOverview from './AdsDetailsViewOverview'
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import useCommunity from '../../hooks/useCommunity'
import {PageTitle} from '../../../_metronic/layout/core'
import PostImagesView from './PostImagesView'

const AdsDetailsViewPage = () => {
  const [post, setPost] = useState({})
  const [loading, setLoading] = useState(true)

  const {id} = useParams()

  const {getPost} = useCommunity()
  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true)
        const {data} = await getPost(id as string)

        setPost(data?.getCommunity.community)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    if (id) {
      init()
    }
  }, [id])

  console.log(post, 'posts')
  return (
    <Routes>
      <Route
        element={
          <>
            <AdsDetailsViewHeader post={post} id={id} loading={loading} />
            <Outlet />
          </>
        }
      >
        <Route
          path='post'
          element={
            <>
              <AdsDetailsViewOverview post={post} id={id} loading={loading} />
            </>
          }
        />

        <Route
          path='post-images'
          element={
            <>
              <PostImagesView post={post} />
            </>
          }
        />

        <Route
          index
          element={<Navigate to='http://localhost:3000/apps/all-community/community' />}
        />
      </Route>
    </Routes>
  )
}

export default AdsDetailsViewPage
