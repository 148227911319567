import React, {useEffect, useState} from 'react'
import AdsDetailsViewHeader from './AdsDetailsViewHeader'
import AdsDetailsViewOverview from './AdsDetailsViewOverview'
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import useCommunity from '../../hooks/useCommunity'
import {PageTitle} from '../../../_metronic/layout/core'
import PostImagesView from './PostImagesView'
import useTicket from '../../hooks/useTicket'
import {ChatInner} from '../../../_metronic/partials'
import {SupportChat} from './SupportChat'
import useAdmin from '../../hooks/useAdmin'
import {useDispatch} from 'react-redux'
import {setAdminId} from '../../../redux/slices/general_slice'
import PostVideosView from './PostVideowView'

interface Posts {
  images: string[]
  videos: string[]
}
const AdsDetailsViewPage = () => {
  const [post, setPost] = useState<Posts>({
    images: [],
    videos: [],
  })
  const [loading, setLoading] = useState(true)
  const [supportTicketID, setSupportTicketID] = useState('')
  const [userId, setUserId] = useState('')

  const {id} = useParams()
  const dispatch = useDispatch()

  const {getTicket} = useTicket()
  const {adminInfo} = useAdmin()
  useEffect(() => {
    const init = async () => {
      try {
        const adminD = await adminInfo()
        const adminId = adminD?.data?.admin?._id
        dispatch(setAdminId(adminId))
        setLoading(true)
        const {data} = await getTicket(id as string)

        setPost(data?.getTicketByIDR.ticket)
        setSupportTicketID(data?.getTicketByIDR.ticket?._id)
        setUserId(data?.getTicketByIDR.ticket?.user?._id)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    if (id) {
      init()
    }
  }, [id])

  console.log(post, 'Ticket here')
  return (
    <Routes>
      <Route
        element={
          <>
            <AdsDetailsViewHeader post={post} id={id} loading={loading} />
            <Outlet />
          </>
        }
      >
        <Route
          path='ticket'
          element={
            <>
              <AdsDetailsViewOverview post={post} id={id} loading={loading} />
            </>
          }
        />

        <Route
          path='ticket-images'
          element={
            <>
              <PostImagesView post={post} />
            </>
          }
        />
        <Route
          path='ticket-videos'
          element={
            <>
              <PostVideosView post={post} />
            </>
          }
        />

        <Route
          path='chat'
          element={
            <SupportChat
              post={post}
              id={id as string}
              supportTicketID={supportTicketID}
              userId={userId}
            />
          }
        />

        <Route index element={<Navigate to='/apps/all-community/community' />} />
      </Route>
    </Routes>
  )
}

export default AdsDetailsViewPage
