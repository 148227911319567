/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {CardsWidget17, CardsWidget20, EngageWidget10} from '../../../_metronic/partials/widgets'
import {useQuery} from '@apollo/client'
import {GET_MEDIA_COUNT} from '../../queries/media.query'
import {CardsWidget18} from '../../../_metronic/partials/widgets/_new/cards/CardWidget18'
import {GET_ALBUMS_COUNT} from '../../queries/album.query'
import {GET_MONTHLY_USER_ANALYTICS} from '../../queries/user.query'
import usePriority from '../../hooks/usePriority'
import useUsers from '../../hooks/useUsers'
import {DashboardFilter} from './DashboardFilter'
import {DataExchange} from 'aws-sdk'

type MediaData = {
  videoCount: ''
  audioCount: ''
}

type AlbumData = {
  audioAlbum: ''
  videoAlbum: ''
}

type chartData = {
  header: string
  total: ''
  maleCount: ''
  femaleCount: ''
  increasePercentage: ''
  thisMonthFreeUsers?: ''
}

type Platform = {
  totalAndroidCount: string
  totalIosCount: string
  totalAndroidCountM: string
  totalAndroidCountY: string
  totalIosCountM: string
  totalIosCountY: string
  totalAndroidMonthlyPlan: string
  totalIosMonthlyPlan: string
  totalAndroidYearlyPlan: string
  totalIosYearlyPlan: string
  totalActiveUsers: string
  activeAndroidUsersCount: string
  activeIosUsersCount: string
  allAndroidCount: string
  allIosCount: string
  allAndroidMonthlyPlan: string
  allIosMonthlyPlan: string
  allAndroidYearlyPlan: string
  allIosYearlyPlan: string
}
const DashboardPage: FC = () => {
  const mediaResponse = useQuery(GET_MEDIA_COUNT)
  const albumResponse = useQuery(GET_ALBUMS_COUNT)
  const monthlyAnalyticsResponse = useQuery(GET_MONTHLY_USER_ANALYTICS)

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const {getPrioritiesAnalytics} = usePriority()

  const {
    getOverallUserAnalytics,
    getOverAllSubscriptionsAnalytics,
    getMonthlySubscriptionsAnalytics,
    getPlatformAnalytics,
  } = useUsers()

  const [platformAnalytics, setPlatformAnalytics] = useState<Platform>({
    totalAndroidCount: '0',
    totalIosCount: '0',
    totalAndroidCountM: '0',
    totalAndroidCountY: '0',
    totalIosCountM: '0',
    totalIosCountY: '0',
    totalAndroidMonthlyPlan: '0',
    totalIosMonthlyPlan: '0',
    totalAndroidYearlyPlan: '0',
    totalIosYearlyPlan: '0',
    totalActiveUsers: '0',
    activeAndroidUsersCount: '0',
    activeIosUsersCount: '0',
    allAndroidCount: '0',
    allIosCount: '0',
    allAndroidMonthlyPlan: '0',
    allIosMonthlyPlan: '0',
    allAndroidYearlyPlan: '0',
    allIosYearlyPlan: '0',
  })

  const [monthlyRevenues, setMonthlyRevenues] = useState(0)

  useEffect(() => {
    const init = async () => {
      const {data} = await getPlatformAnalytics('')

      console.log(data, 'platform')
      setPlatformAnalytics(data?.getPlatformBasedSubscriptions)

      const iosCount = data?.getPlatformBasedSubscriptions?.totalIosCountM
      const androidCount = data?.getPlatformBasedSubscriptions?.totalAndroidCountM

      const monthlyRevenue = (parseInt(iosCount) + parseInt(androidCount)) * 9.9

      setMonthlyRevenues(monthlyRevenue)
    }

    init()
  }, [])
  const [monthlySubscription, setMonthlySubscription] = useState<any>({
    header: 'Monthly Subscriptions',
    freeUsers: '',
    subscribedUsers: '',
  })

  const [overAllSubscription, setOverAllSubscription] = useState<any>({
    freeUsers: '',
    subscribedUsers: '',
    header: 'Overall Subscriptions',
  })

  const [mediaData, setMediaData] = useState<MediaData>({
    videoCount: '',
    audioCount: '',
  })
  const [albumCount, setAlbumCount] = useState<AlbumData>({
    audioAlbum: '',
    videoAlbum: '',
  })
  const [chartData, setChartData] = useState<chartData>({
    header: 'Registration By Month',
    total: '',
    maleCount: '',
    femaleCount: '',
    increasePercentage: '',
    thisMonthFreeUsers: '',
  })

  const [prioritiesData, setPrioritiesData] = useState<any>({
    featuredSeriesCount: '',
    featuredVideosCount: '',
    popularSeriesCount: '',
    recommendedPlaylistsCount: '',
  })

  const [overallData, setOverAllData] = useState<chartData>({
    header: ' Overall Registration',
    total: '',
    maleCount: '',
    femaleCount: '',
    increasePercentage: '',
  })

  useEffect(() => {
    if (!mediaResponse.loading) {
      const counts = mediaResponse.data.getMediaCount.data
      setMediaData(counts)
    }
  }, [mediaResponse.loading])

  useEffect(() => {
    if (!albumResponse.loading) {
      const counts = albumResponse.data.getAlbumCountsR.data
      setAlbumCount(counts)
    }
  }, [albumResponse.loading])

  useEffect(() => {
    if (!monthlyAnalyticsResponse.loading) {
      const counts = monthlyAnalyticsResponse.data.getMonthlyUserStatsR.data
      setChartData({...counts, header: 'Registration By Month'})
    }
  }, [monthlyAnalyticsResponse.loading])

  useEffect(() => {
    const init = async () => {
      try {
        const {data, error} = await getPrioritiesAnalytics()
        setPrioritiesData(data?.prioritiesAnalytics)
      } catch (error) {}
    }

    init()
  }, [])

  useEffect(() => {
    const init = async () => {
      try {
        const {data, error} = await getOverAllSubscriptionsAnalytics(startDate, endDate)
        setOverAllSubscription({
          ...data?.overAllSubscriptionsAnalytics,
          header: 'Overall Subscription Analytics',
        })
      } catch (error) {}
    }

    init()
  }, [startDate, endDate])

  useEffect(() => {
    const init = async () => {
      try {
        const {data, error} = await getOverallUserAnalytics(startDate, endDate)

        setOverAllData({
          ...data?.overAllRegisteredAnalytics,
          increasePercentage: chartData.increasePercentage,
          header: 'Overall Registration',
        })
      } catch (error) {}
    }
    init()
  }, [startDate, endDate])

  // Monthly User Subscriptions Analytics

  useEffect(() => {
    const init = async () => {
      try {
        const {data, error} = await getMonthlySubscriptionsAnalytics()

        setMonthlySubscription({
          ...data?.monthlySubscriptionAnalytics,
          header: 'Monthly Subscription Analytics',
        })
      } catch (error: any) {}
    }

    init()
  }, [])
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-6 col-lg-3 min-h-100px'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${albumCount?.audioAlbum}`}
            description='Total Audio Albums'
            progressNumber=''
            progressBar='ongoing contest'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        <div className='col-sm-6 col-lg-3 min-h-100px'>
          <CardsWidget20
            className='h-100px mx-1 mb-3 mb-xl-5'
            cardNumber={`${mediaData?.audioCount}`}
            description='Total Individual Audios'
            progressNumber=''
            progressBar='ongoing contest'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-6 col-lg-3 min-h-100px'>
          <CardsWidget20
            className='h-100px mx-1 mb-3 mb-xl-5'
            cardNumber={`${albumCount?.videoAlbum}`}
            description='Total Video Albums'
            progressNumber=''
            progressBar='ongoing contest'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        <div className='col-sm-6 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${mediaData?.videoCount}`}
            description='Total Individual Videos'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        <div className='col-sm-6 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${prioritiesData?.featuredSeriesCount}`}
            description='Total Featured Series'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        <div className='col-sm-6 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${prioritiesData?.featuredVideosCount}`}
            description='Total Featured Videos'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        <div className='col-sm-6 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${prioritiesData?.popularSeriesCount}`}
            description='Total Popular Series'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        <div className='col-sm-6 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${prioritiesData?.recommendedPlaylistsCount}`}
            description='Total Latest Albums'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <h3>Over All Registrations and Subscriptions</h3>
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <DashboardFilter
            sDate={startDate}
            eDate={endDate}
            setSDate={setStartDate}
            setEDate={setEndDate}
          />
        </div>

        <div className='col-sm-6 col-lg-4 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${overallData?.total}`}
            description='Total Users'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-6 col-lg-4 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-4 mb-xl-5'
            cardNumber={`${overallData?.maleCount}`}
            description='Male Users'
            progressNumber='437'
            progressBar='Total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-6 col-lg-4 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${overallData?.femaleCount}`}
            description='Female Users'
            progressNumber='437'
            progressBar='Total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-6 col-lg-6 min-h-100px col-sm-6 '>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${overAllSubscription?.subscribedUsers}`}
            description='Total Subscribed Users'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-6 col-lg-6 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${overAllSubscription?.freeUsers}`}
            description='Total Free Users'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.allAndroidCount}`}
            description='Android Total Subscriptions'
            progressNumber='437'
            progressBar='Total Android Users '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.allAndroidMonthlyPlan}`}
            description='Android Monthly plan Subscribers'
            progressNumber='437'
            progressBar='Total iOS Users '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.allAndroidYearlyPlan}`}
            description='Android Yearly plan Subscribers'
            progressNumber='437'
            progressBar='Total iOS Users '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`$ ${
              parseInt(platformAnalytics?.allAndroidMonthlyPlan) * 10 +
              parseInt(platformAnalytics?.allAndroidYearlyPlan) * 100
            }`}
            description='Android Total  Revenues'
            progressNumber='437'
            progressBar='Total Monthly  Revenues '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.allIosCount}`}
            description='iOS Total Subscribers'
            progressNumber='437'
            progressBar='Total iOS Users '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.allIosMonthlyPlan}`}
            description='iOS Monthly Subscribers'
            progressNumber='437'
            progressBar='Total Monthly  Revenues '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.allIosYearlyPlan}`}
            description='iOS Yearly Subscribers'
            progressNumber='437'
            progressBar='Total Monthly  Revenues '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`$ ${
              parseInt(platformAnalytics?.allIosMonthlyPlan) * 10 +
              parseInt(platformAnalytics?.allIosYearlyPlan) * 100
            }`}
            description='iOS Total Revenues'
            progressNumber='437'
            progressBar='Total Monthly  Revenues '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <h3>Current Month Registrations</h3>

        <div className='col-sm-6 col-lg-4 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${chartData?.total}`}
            description='Monthly Total Users'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-6 col-lg-4 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-4 mb-xl-5'
            cardNumber={`${chartData?.maleCount}`}
            description='Monthly Male Users'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-6 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${chartData?.femaleCount}`}
            description='Female Users'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-6 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.totalActiveUsers}`}
            description='Total active users this month'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-6 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.activeAndroidUsersCount}`}
            description='Total active users Android'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-6 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${
              parseInt(platformAnalytics?.totalActiveUsers) -
              parseInt(platformAnalytics?.activeAndroidUsersCount)
            }`}
            description='Total active users iOS'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-6 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${chartData?.thisMonthFreeUsers}`}
            description='Total free users this month'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <h3>Current Month Subscriptions</h3>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.totalAndroidCountM}`}
            description='Android Total Subscriptions'
            progressNumber='437'
            progressBar='Total Android Users '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.totalAndroidMonthlyPlan}`}
            description='Android Monthly plan Subscribers'
            progressNumber='437'
            progressBar='Total iOS Users '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.totalAndroidYearlyPlan}`}
            description='Android Yearly plan Subscribers'
            progressNumber='437'
            progressBar='Total iOS Users '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`$ ${parseInt(platformAnalytics?.totalAndroidCountM) * 10}`}
            description='Android Total  Revenues'
            progressNumber='437'
            progressBar='Total Monthly  Revenues '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.totalIosCountM}`}
            description='iOS Total Subscribers'
            progressNumber='437'
            progressBar='Total iOS Users '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.totalIosMonthlyPlan}`}
            description='iOS Monthly Subscribers'
            progressNumber='437'
            progressBar='Total Monthly  Revenues '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${platformAnalytics?.totalIosYearlyPlan}`}
            description='iOS Yearly Subscribers'
            progressNumber='437'
            progressBar='Total Monthly  Revenues '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-4 col-lg-3 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`$ ${parseInt(platformAnalytics?.totalIosCountM) * 10}`}
            description='iOS Total Revenues'
            progressNumber='437'
            progressBar='Total Monthly  Revenues '
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        {/* <div className='col-sm-6 col-lg-6 min-h-100px col-sm-6 '>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${monthlySubscription?.subscribedUsers}`}
            description='Monthly Subscribed Users'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-sm-6 col-lg-6 min-h-100px col-sm-6'>
          <CardsWidget20
            className='h-100px mb-3 mb-xl-5'
            cardNumber={`${monthlySubscription?.freeUsers}`}
            description='Monthly Free Users'
            progressNumber='437'
            progressBar='total users'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div> */}

        {/* <div className='col-md-6 h-md-50 h-lg-50 col-lg-6 col-xl-6 col-xxl-6 mb-md-3 mb-xl-5'>
          <CardsWidget18 chartData={chartData} className='h-md-50 mb-3 mb-xl-5' />
        </div>

        <div className='col-md-6 h-md-50 h-lg-50 col-lg-6 col-xl-6 col-xxl-6 mb-md-3 mb-xl-5'>
          <CardsWidget18 chartData={overallData} className='h-md-50 mb-3 mb-xl-5' />
        </div>

        <div className='col-md-6 h-md-50 h-lg-50 col-lg-6 col-xl-6 col-xxl-6 mb-md-3 mb-xl-5'>
          <CardsWidget17 chartData={monthlySubscription} className='h-md-50 mb-3 mb-xl-5' />
        </div>

        <div className='col-md-6 h-md-50 h-lg-50 col-lg-6 col-xl-6 col-xxl-6 mb-md-3 mb-xl-5'>
          <CardsWidget17 chartData={overAllSubscription} className='h-md-50 mb-3 mb-xl-5' />
        </div> */}
      </div>

      {/* begin::Col */}
      <div className='col-12 '>
        <EngageWidget10 className='h-md-100' />
      </div>
      {/* end::Col */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
      </div>
    </div> */}
      {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div> */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
