import {gql} from '@apollo/client'

export const FETCH_PAGEABLE_ALBUMS = gql`
  query GetAlbumsPaginated(
    $page: Int
    $pageSize: Int
    $type: String
    $startDate: String
    $endDate: String
    $searchString: String
    $ids: [String]
    $category: String
    $subscription: String
  ) {
    getAlbumsPaginated(
      page: $page
      pageSize: $pageSize
      type: $type
      startDate: $startDate
      endDate: $endDate
      searchString: $searchString
      ids: $ids
      category: $category
      subscription: $subscription
    ) {
      message
      status
      data {
        _id
        title
        cover_image_url
        thumbnail_image_url
        description
        media {
          _id
          title
          speaker
          url
          nature
          albumID
          description
          thumbnail_image_url
          type
          isLiked
          createdAt
        }
        category {
          _id
          name
          status
          priority
          image
        }
        isLiked
        nature
        type
        createdAt
        status
      }
      totalDocs
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      prevPage
      nextPage
    }
  }
`

export const ADD_ALBUM = gql`
  mutation Mutation(
    $title: String
    $description: String
    $category_name: String
    $type: String
    $nature: String
    $thumbnail_image_url: String
    $cover_image_url: String
  ) {
    addAlbum(
      title: $title
      description: $description
      category_name: $category_name
      type: $type
      nature: $nature
      thumbnail_image_url: $thumbnail_image_url
      cover_image_url: $cover_image_url
    ) {
      message
      status
    }
  }
`

export const UPDATE_ALBUM = gql`
  mutation UpdateAlbum(
    $id: String
    $title: String
    $cover_image_url: String
    $thumbnail_image_url: String
    $description: String
    $category_name: String
    $nature: String
    $isLiked: Boolean
    $status: String
  ) {
    updateAlbum(
      id: $id
      title: $title
      cover_image_url: $cover_image_url
      thumbnail_image_url: $thumbnail_image_url
      category_name: $category_name
      nature: $nature
      description: $description
      isLiked: $isLiked
      status: $status
    ) {
      message
      status
      data {
        _id
        title
        cover_image_url
        thumbnail_image_url
        description
        media {
          _id
        }
        category {
          _id
        }
        isLiked
        nature
        type
        createdAt
      }
    }
  }
`

export const GET_ALBUM_BY_ID = gql`
  query GetAlbumByID($getAlbumByIdId: String) {
    getAlbumByID(id: $getAlbumByIdId) {
      message
      status
      data {
        _id
        title
        cover_image_url
        thumbnail_image_url
        description
        nature
        media {
          _id
          title
          speaker
          url
          thumbnail_image_url
          type
          isLiked
          averageWatchHour
        }
        category {
          _id
          name
        }
        isLiked
        type
        createdAt
        status
      }
    }
  }
`

export const FETCH_ALBUM_MEDIAS = gql`
  query FetchAlbumsMedia(
    $fetchAlbumsMediaId: String
    $page: Int
    $pageSize: Int
    $searchParams: String
  ) {
    fetchAlbumsMedia(
      id: $fetchAlbumsMediaId
      page: $page
      pageSize: $pageSize
      searchParams: $searchParams
    ) {
      data {
        _id
        title
        speaker
        url
        thumbnail_image_url
        albumID
        type
        isLiked
        averageWatchHour
        createdAt
      }
      status
    }
  }
`

export const ADD_MEDIA_TO_ALBUM = gql`
  mutation AddMediaToAlbum(
    $addMediaToAlbumId: String
    $title: String
    $speaker: String
    $url: String
    $type: String
    $thumbnailImageUrl: String
    $nature: String
  ) {
    addMediaToAlbum(
      id: $addMediaToAlbumId
      title: $title
      speaker: $speaker
      url: $url
      type: $type
      thumbnail_image_url: $thumbnailImageUrl
      nature: $nature
    ) {
      message
      status
      data {
        _id
        title
        cover_image_url
        thumbnail_image_url
        description
        media {
          _id
          title
          speaker
          url
          nature
          thumbnail_image_url
          type
          isLiked
          createdAt
        }
        category {
          _id
          name
          status
          priority
          image
        }
        isLiked
        nature
        type
        createdAt
        status
      }
    }
  }
`

export const GET_ALBUMS_COUNT = gql`
  query GetAlbumCountsR {
    getAlbumCountsR {
      data {
        audioAlbum
        videoAlbum
      }
      message
      status
    }
  }
`

export const DELETE_ALBUM_BY_ID = gql`
  mutation DeleteAlbumR($deleteAlbumRId: String) {
    deleteAlbumR(id: $deleteAlbumRId) {
      message
      status
    }
  }
`
