import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  selectedAlbums: [],
  selectedCategory: [],
  selectedAudioAlbums: [],
  selectedVideoAlbums: [],
  selectedVideos: [],
  selectedAudio: [],
  selectedAds: [],
  selectedFeaturedSeries: [],
  selectedPopularSeries: [],
  selectedFeaturedVideos: [],
  selectedRecommendedPlaylist: [],
  individualAudios: [],
  individualVideos: [],
  adminId: '',
  audioAlbums: [],
  videoAlbums: [],
  done: {},
  selectedFeedbacks: [],
}

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setAdminId: (state: any, action: {payload: string}) => {
      state.adminId = action.payload
    },
    setSelectedAlbums: (state: any, action: {payload: any[]}) => {
      state.selectedAlbums = action.payload
    },
    setIndividualAudios: (state: any, action: {payload: any[]}) => {
      state.individualAudios = action.payload
    },
    setIndividualVideos: (state: any, action: {payload: any[]}) => {
      state.individualVideos = action.payload
    },
    setAudioAlbums: (state: any, action: {payload: any[]}) => {
      state.audioAlbums = action.payload
    },
    setVideoAlbums: (state: any, action: {payload: any[]}) => {
      state.videoAlbums = action.payload
    },
    setSelectedCategory: (state: any, action: {payload: any[]}) => {
      state.selectedCategory = action.payload
    },
    setSelectedAudioAlbums: (state: any, action: {payload: any[]}) => {
      state.selectedAudioAlbums = action.payload
    },
    setSelectedVideoAlbums: (state: any, action: {payload: any[]}) => {
      state.selectedVideoAlbums = action.payload
    },
    setSelectedVideos: (state: any, action: {payload: any[]}) => {
      state.selectedVideos = action.payload
    },
    setSelectedAudio: (state: any, action: {payload: any[]}) => {
      state.selectedAudio = action.payload
    },
    setSelectedAds: (state: any, action: {payload: any[]}) => {
      state.selectedAds = action.payload
    },
    setSelectedFeaturedSeries: (state: any, action: {payload: any[]}) => {
      state.selectedFeaturedSeries = action.payload
    },
    setSelectedPopularSeries: (state: any, action: {payload: any[]}) => {
      state.selectedPopularSeries = action.payload
    },
    setSelectedFeaturedVideos: (state: any, action: {payload: any[]}) => {
      state.selectedFeaturedVideos = action.payload
    },
    setSelectedRecommendedPlaylist: (state: any, action: {payload: any[]}) => {
      state.selectedRecommendedPlaylist = action.payload
    },
    setDone: (state: any, action: {payload: any}) => {
      state.done = action.payload
    },
    setSelectedFeedbacks: (state: any, action: {payload: any[]}) => {
      state.selectedFeedbacks = action.payload
    },
  },
})

export const {
  setAdminId,
  setSelectedFeedbacks,
  setSelectedCategory,
  setSelectedAudioAlbums,
  setSelectedVideoAlbums,
  setSelectedVideos,
  setSelectedAudio,
  setSelectedAds,
  setSelectedFeaturedSeries,
  setSelectedFeaturedVideos,
  setSelectedPopularSeries,
  setSelectedRecommendedPlaylist,
  setIndividualAudios,
  setIndividualVideos,
  setAudioAlbums,
  setVideoAlbums,
  setDone,
  setSelectedAlbums,
} = generalSlice.actions

export default generalSlice.reducer
