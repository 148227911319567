/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useLocation, useParams} from 'react-router'

import {isImageUrl} from '../../helper/imageHelpers'
import {useMutation} from '@apollo/client'
import useCommunity from '../../hooks/useCommunity'
import {Link} from 'react-router-dom'

type Props = {
  post: any
  id: any
  loading: boolean
}
const AdsDetailsViewHeader: React.FC<Props> = ({post, id, loading}) => {
  const [activate, setActivate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('Are you sure you want to delete this Posts?')
  const [showModalDelete, setShowModalDelete] = useState(false)

  const location = useLocation()
  const {deleteManyPostsByID} = useCommunity()
  useEffect(() => {
    setActivate(post?.status === 'active')
  }, [post])

  if (loading) {
    return <> Loading</>
  }

  const handleDelete = async () => {
    try {
      setIsLoading(true)
      await deleteManyPostsByID([id])
      setMessage('Posts Deleted Successfully')
      setIsDone(true)
    } catch (error) {
      setError(true)
      setMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0 position-relative'>
        {/* Top right actions container */}
        <div className='position-absolute top-0 end-0 m-6 d-flex align-items-center'>
          <div className='form-check form-switch form-check-custom form-check-solid me-3'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={activate}
              onChange={() => setShowModal(true)}
              id='flexSwitchDefault'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
            />
            <label htmlFor='flexSwitchDefault' className='form-check-label fw-bold'>
              {activate ? 'Active' : 'Inactive'}
            </label>
          </div>
          <button
            disabled={isLoading}
            type='button'
            className='btn btn-sm btn-danger fw-bold'
            onClick={() => setShowModalDelete(true)}
          >
            Delete post
          </button>
        </div>

        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <div className='symbol-label'>
                <img
                  src={
                    post?.posted_by?.profile_picture
                      ? post?.posted_by?.profile_picture
                      : toAbsoluteUrl('/media/logos/munroe_logo.png')
                  }
                  alt={'image'}
                  className='w-100 m-auto rounded'
                />
              </div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                {/* Name */}
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {post?.posted_by?.first_name
                      ? `${post?.posted_by?.first_name} ${post?.posted_by?.last_name}`
                      : ' '}
                  </a>
                </div>

                {/* Contact Information */}
                <div className='d-flex flex-column flex-wrap fw-bold fs-6 pe-2'>
                  {/* Gender */}
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5'
                  >
                    {post?.posted_by?.gender?.toLowerCase() === 'male' ? (
                      <i className='bi bi-gender-male'></i>
                    ) : (
                      <i className='bi bi-gender-female'></i>
                    )}
                    {post?.posted_by?.gender ? (
                      <p className='m-2'>{post?.posted_by?.gender}</p>
                    ) : (
                      ' '
                    )}
                  </a>

                  {/* Phone */}
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5'
                  >
                    <i className='bi bi-telephone'></i>
                    {post?.posted_by?.phone_number ? (
                      <p className='m-2'>{post?.posted_by?.phone_number}</p>
                    ) : (
                      ' '
                    )}
                  </a>

                  {/* Email */}
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                    />
                    {post?.posted_by?.email ? <p className='m-2'>{post?.posted_by?.email}</p> : ' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Tabs */}
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/apps/all-community/community/details-page/${id}/post` &&
                    'active')
                }
                to='post'
              >
                Post Details
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/apps/all-community/community/details-page/${id}/post-images` && 'active')
                }
                to='post-images'
              >
                Post Images
              </Link>
            </li>
          </ul>
        </div>

        {showModal && (
          <MessageModal
            id={id}
            activate={activate}
            showModal={showModal}
            setShowModal={setShowModal}
            setActivate={setActivate}
            post={post}
          />
        )}

        {showModalDelete && (
          <MessageModalDelete
            showModal={showModalDelete}
            setShowModal={setShowModalDelete}
            error={error}
            message={message}
            handleDelete={handleDelete}
            isDone={isDone}
            setIsDone={setIsDone}
            isLoading={isLoading}
            message={message}
            setMessage={setMessage}
          />
        )}
      </div>
    </div>
  )
}

const MessageModal = ({showModal, setShowModal, message, activate, setActivate, id, post}) => {
  const {updateCommunityStatus} = useCommunity()

  const handleStatus = async () => {
    try {
      let status = activate ? 'inactive' : 'active'
      await updateCommunityStatus(id, status)
      setActivate((pv) => !pv)
    } catch (error) {
    } finally {
      setShowModal(false)
    }
  }
  return (
    <>
      <>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          <div className='modal-dialog modal-dialog-centered mw-550px'>
            <div className='modal-content'>
              <div className='modal-body scroll-y '>
                <div className='modal-header'>
                  <h5 className='modal-title fs-2'>
                    {' '}
                    {post?.status !== 'active' ? 'Inactive' : 'Active'}
                  </h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => setShowModal(false)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body fs-3'>
                  <p>
                    {activate
                      ? 'Are you sure you want to disable this Post ?'
                      : 'Are you sure you want to enable this Post ?'}
                  </p>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-light'
                    onClick={() => setShowModal(false)}
                    data-bs-dismiss='modal'
                  >
                    No
                  </button>
                  <button onClick={handleStatus} type='button' className='btn btn-primary'>
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-backdrop fade show'></div>
      </>
    </>
  )
}

const MessageModalDelete = ({
  id,
  isDone,
  setIsDone,
  showModal,
  setShowModal,
  error,
  isLoading,
  message,
  setMessage,
  handleDelete,
}: any) => {
  return (
    <>
      <>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          <div className='modal-dialog modal-dialog-centered mw-550px'>
            <div className='modal-content'>
              <div className='modal-body scroll-y '>
                <div className='modal-header'>
                  <h5 className='modal-title fs-2'> {'Delete'}</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => setShowModal(false)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body fs-3'>
                  <p>{message}</p>
                </div>
                <div className='modal-footer'>
                  {isDone ? (
                    <button
                      type='button'
                      className='btn btn-light'
                      onClick={() => {
                        setShowModal(false)
                        window.location.href = '/apps/all-community/community'
                      }}
                      data-bs-dismiss='modal'
                    >
                      Close
                    </button>
                  ) : (
                    <>
                      <button
                        type='button'
                        className='btn btn-light'
                        onClick={() => setShowModal(false)}
                        data-bs-dismiss='modal'
                      >
                        No
                      </button>
                      <button
                        onClick={async () => {
                          setMessage('Please wait...')
                          await handleDelete()
                        }}
                        type='button'
                        disabled={isLoading}
                        className='btn btn-primary'
                      >
                        {isLoading ? 'Please wait...' : 'Yes'}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-backdrop fade show'></div>
      </>
    </>
  )
}

export default AdsDetailsViewHeader
