// @ts-nocheck
import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useSelector} from 'react-redux'
import useFeedback from '../../../../../hooks/useFeedBack'
import useCommunity from '../../../../../hooks/useCommunity'
import useTicket from '../../../../../hooks/useTicket'

const FeedbackListAction = () => {
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('Are you sure you want to delete this Ticket?')
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const selectedFeedbacks = useSelector((state: any) => state.general.selectedFeedbacks)
  const {deleteTickets} = useTicket()
  const handleDelete = async () => {
    try {
      setIsLoading(true)
      await deleteTickets(selectedFeedbacks)
      setMessage('Ticket Deleted Successfully')
      setIsDone(true)
    } catch (error) {
      setError(true)
      setMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {showModal && (
        <MessageModal
          showModal={showModal}
          setShowModal={setShowModal}
          error={error}
          message={message}
          handleDelete={handleDelete}
          isDone={isDone}
          setIsDone={setIsDone}
          isLoading={isLoading}
          message={message}
          setMessage={setMessage}
        />
      )}
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2' />
        Options
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Actions */}
          <div className='d-flex gap-2 flex-wrap'>
            <div className='row my-4'>
              <button
                disabled={isLoading}
                type='button'
                onClick={() => setShowModal(true)}
                className='btn btn-danger fw-bold'
                data-kt-menu-dismiss='true'
                data-kt-user-table-filter='filter'
              >
                Delete Selected Tickets
              </button>
            </div>
          </div>
          {/* end::Actions */}
        </div>

        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

const MessageModal = ({
  id,
  isDone,
  setIsDone,
  showModal,
  setShowModal,
  error,
  isLoading,
  message,
  setMessage,
  handleDelete,
}: any) => {
  return (
    <>
      <>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          <div className='modal-dialog modal-dialog-centered mw-550px'>
            <div className='modal-content'>
              <div className='modal-body scroll-y '>
                <div className='modal-header'>
                  <h5 className='modal-title fs-2'> {'Delete'}</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => setShowModal(false)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body fs-3'>
                  <p>{message}</p>
                </div>
                <div className='modal-footer'>
                  {isDone ? (
                    <button
                      type='button'
                      className='btn btn-light'
                      onClick={() => {
                        setShowModal(false)
                        window.location.reload()
                      }}
                      data-bs-dismiss='modal'
                    >
                      Close
                    </button>
                  ) : (
                    <>
                      <button
                        type='button'
                        className='btn btn-light'
                        onClick={() => setShowModal(false)}
                        data-bs-dismiss='modal'
                      >
                        No
                      </button>
                      <button
                        onClick={async () => {
                          setMessage('Please wait...')
                          await handleDelete()
                        }}
                        type='button'
                        disabled={isLoading}
                        className='btn btn-primary'
                      >
                        {isLoading ? 'Please wait...' : 'Yes'}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-backdrop fade show'></div>
      </>
    </>
  )
}
export {FeedbackListAction}
