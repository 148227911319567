// @ts-nocheck
import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Leagues from '../pages/Leagues/Leagues'
import Ads from '../pages/Ads/Ads'
import Rewards from '../pages/Rewards/Rewards'
import Contact from '../pages/Contact/Contact'
import Affiliate from '../pages/Affiliate/Affiliate'
import PrivacyPolicy from '../pages/Settings/PrivacyPolicy'
import FAQ from '../pages/Settings/FAQ'
import TermsandCondition from '../pages/Settings/TermsandCondition'
import VersionControl from '../pages/Settings/VersionControl'
import UsersManagement from '../pages/Users/UsersManagement'

import FeedbackManagement from '../pages/feedback-management/UsersManagement'
import CommunityManagement from '../pages/community-management/UsersManagement'
import CreateNewAdd from '../pages/Ads/CreateNewAdd'
import CreateNewReward from '../pages/Rewards/reward-points/reward-points-list/components/CreateNewReward'
import RewardDetails from '../pages/Rewards/reward-points/reward-points-list/components/RewardDetails'
import EditRewards from '../pages/Rewards/reward-points/reward-points-list/components/EditRewards'
import EditAffiliate from '../pages/Affiliate/create-affiliate/affiliate-list-details/EditAffiliate'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const SubAdminRoutes = lazy(() => import('../pages/SubAdmins/SubAdminDetails/SubAdminRoutes'))
  const CategoryRoutes = lazy(
    () => import('../pages/category-management/CategoryDetails/CategoryRoutes')
  )
  const UsersDetailsRoutes = lazy(() => import('../pages/Users/UserDetailsRoutes'))
  const LeagueDetailsRoutes = lazy(
    () => import('../pages/Leagues/leagues-details/LeagueDetailsRoutes')
  )
  const ContestDetailsRoutes = lazy(
    () => import('../pages/Contest/contests-details/ContestDetailsPage')
  )
  const MultiPlayerContestDetailsRoutes = lazy(
    () =>
      import('../pages/Contest/contests-details/multiplayer-contests/MultiPlayerDetailsOverview')
  )
  const AdsDetailsRoutes = lazy(() => import('../pages/Ads/ads-details-page/AdsDetailsViewPage'))
  const LeagueDetailsViewRoutes = lazy(
    () => import('../pages/Leagues/leagues-details/LeaguesDetailsViewPage')
  )
  const RewardPointsDetailsRoutes = lazy(
    () => import('../pages/Rewards/reward-points/reward-points-details/RewardPointsDetailsRoutes')
  )
  const RewardRequestsDetailsRoutes = lazy(
    () =>
      import('../pages/Rewards/reward-requests/reward-requests-details/RewardRequestsDetailsRoutes')
  )
  const LeaguesRoutes = lazy(() => import('../pages/Leagues/LeaguesRoutes'))

  const ReportDetailsRoutes = lazy(
    () => import('../pages/Reports/reports-details-page/ReportsDetailsViewPage')
  )

  const AdsRoutes = lazy(() => import('../pages/Ads/AdsRoutes'))
  const RewardPointsRoutes = lazy(() => import('../pages/Rewards/reward-points/RewardPointsRoutes'))
  const RewardRequestsRoutes = lazy(
    () => import('../pages/Rewards/reward-requests/RewardRequestsRoutes')
  )
  const AffiliateListRoutes = lazy(
    () => import('../pages/Affiliate/create-affiliate/CreateAffiliateRoutes')
  )
  const AffiliateListDetailsRoutes = lazy(
    () =>
      import(
        '../pages/Affiliate/create-affiliate/affiliate-list-details/AffiliateListDetailsRoutes'
      )
  )
  const AffiliateRegisterationRoutes = lazy(
    () => import('../pages/Affiliate/affiliate-registeration/AffiliateRegisterationRoutes')
  )
  const CreateAffiliateForm = lazy(
    () => import('../pages/Affiliate/create-affiliate/CreateAffiliateForm')
  )
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const CategoryPage = lazy(() => import('../pages/category-management/CategoryPage'))
  const PriorityPage = lazy(() => import('../pages/priority-management/PriorityPage'))
  const LatestAlbumPage = lazy(() => import('../pages/latest-album-management/LatestAlbumPage'))
  const FeaturedSeriesPage = lazy(
    () => import('../pages/featured-series-management/FeaturedSeriesPage')
  )
  const FeaturedVideoPage = lazy(
    () => import('../pages/featured-video-management/FeaturedVideoPage')
  )
  const PopularSeriesPage = lazy(
    () => import('../pages/popular-series-management/PopularSeriesPage')
  )
  const PopularVideoPage = lazy(() => import('../pages/popular-video-management/PopularVideoPage'))
  const RecommendedPlaylistPage = lazy(
    () => import('../pages/recommended-playlist-management/RecommendedPlaylistPage')
  )

  /* Notification Page */
  const NotificationPage = lazy(() => import('../pages/notification-management/NotificationPage'))

  /* End  */

  const AudioPage = lazy(() => import('../pages/audio-management/AudioPage'))

  const IndividualAudioPage = lazy(
    () => import('../pages/individual-audio-management/IndividualAudioPage')
  )
  const VideoPage = lazy(() => import('../pages/video-management/VideoPage'))
  const IndividualVideoPage = lazy(
    () => import('../pages/individual-video-management/IndividualVideoPage')
  )
  const ContestsPage = lazy(() => import('../pages/Contest/ContestsPage'))

  const ReportsPage = lazy(() => import('../pages/Reports/ReportsRoutes'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />

        {/* Routes for new pages begin */}
        {/* <Route path='all-users' element={<Users />} /> */}

        {/* <Route path='contest-management' element={<Contest />} /> */}
        <Route path='leagues-management' element={<Leagues />} />
        <Route path='ads' element={<Ads />} />
        <Route path='rewards' element={<Rewards />} />

        <Route
          path='apps/affiliate-management/affiliate-list/details-page/:id/*'
          element={
            <SuspensedView>
              <AffiliateListDetailsRoutes />
            </SuspensedView>
          }
        ></Route>
        {/* Affiliate management begins */}
        <Route
          path='apps/affiliate-management/affiliate-list/create-affiliate'
          element={
            <SuspensedView>
              <CreateAffiliateForm />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='apps/affiliate-management/affiliate-list/:id/edit'
          element={
            <SuspensedView>
              <EditAffiliate />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='apps/affiliate-management/*'
          element={
            <SuspensedView>
              <AffiliateListRoutes />
              <AffiliateRegisterationRoutes />
            </SuspensedView>
          }
        ></Route>
        {/* Affiliate management ends */}
        <Route
          path='apps/rewards-management/reward-requests/details-view/:id/*'
          element={
            <SuspensedView>
              <RewardRequestsDetailsRoutes />
            </SuspensedView>
          }
        ></Route>
        <Route
          path='apps/rewards-management/reward-points/details-view/:id/*'
          element={
            <SuspensedView>
              <RewardPointsDetailsRoutes />
            </SuspensedView>
          }
        ></Route>
        <Route
          path='apps/rewards-management/*'
          element={
            <SuspensedView>
              <RewardPointsRoutes />
              <RewardRequestsRoutes />
            </SuspensedView>
          }
        ></Route>

        <Route path='apps/reward-management/create-new-reward' element={<CreateNewReward />} />
        <Route
          path='apps/rewards-management/reward-points/details-page/:id/reward'
          element={
            <SuspensedView>
              <RewardDetails />
            </SuspensedView>
          }
        />

        <Route
          path='apps/rewards-management/reward-points/details-page/:id/edit-reward'
          element={
            <SuspensedView>
              <EditRewards />
            </SuspensedView>
          }
        />

        <Route path='affiliate' element={<Affiliate />} />
        <Route path='contact' element={<Contact />} />

        <Route path='settings/terms-and-condition' element={<TermsandCondition />} />
        <Route path='settings/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='settings/faq' element={<FAQ />} />
        <Route path='settings/version-control' element={<VersionControl />} />

        {/* Routes for new pages end */}

        {/* Sub Admin Details Page Begin */}
        <Route
          path='apps/user-management/users/details-page/:id/*'
          element={
            <SuspensedView>
              <SubAdminRoutes />
            </SuspensedView>
          }
        />

        {/* Sub Admin Details Page End */}

        {/* Reports Page */}

        <Route
          path='/apps/reports-management/*'
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/reports-management/reports/details-page/:id/*'
          element={
            <SuspensedView>
              <ReportDetailsRoutes />
            </SuspensedView>
          }
        />

        {/* End Reports Page */}

        {/* Category Details Page Begin */}
        <Route
          path='apps/category-management/categories/details-page/:id/*'
          element={
            <SuspensedView>
              <CategoryRoutes />
            </SuspensedView>
          }
        />
        {/* Category Details Page End */}

        {/* Sub Admin table Route begins */}
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Sub Admin table Route ends */}

        {/* Category table Route begins */}
        <Route
          path='apps/category-management/*'
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />
        {/* Category table Route ends */}

        {/* Notification Page */}
        <Route
          path='apps/notification-management/*'
          element={
            <SuspensedView>
              <NotificationPage />
            </SuspensedView>
          }
        />
        {/* Notification Page End*/}

        {/* Priority Section Begin */}
        <Route
          path='apps/priority-management/*'
          element={
            <SuspensedView>
              <PriorityPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/featured-series-management/*'
          element={
            <SuspensedView>
              <FeaturedSeriesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/popular-series-management/*'
          element={
            <SuspensedView>
              <PopularSeriesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/featured-videos-management/*'
          element={
            <SuspensedView>
              <FeaturedVideoPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/popular-videos-management/*'
          element={
            <SuspensedView>
              <PopularVideoPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/recommended-playlist-management/*'
          element={
            <SuspensedView>
              <RecommendedPlaylistPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/latest-album-management/*'
          element={
            <SuspensedView>
              <LatestAlbumPage />
            </SuspensedView>
          }
        />

        {/* Priority Section End */}

        <Route
          path='apps/audio-management/*'
          element={
            <SuspensedView>
              <AudioPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/video-management/*'
          element={
            <SuspensedView>
              <VideoPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/individual-audio/*'
          element={
            <SuspensedView>
              <IndividualAudioPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/individual-video/*'
          element={
            <SuspensedView>
              <IndividualVideoPage />
            </SuspensedView>
          }
        />

        {/* All Users Details page Routes begin */}
        <Route
          path='apps/all-users/users/details-page/:id/*'
          element={
            <SuspensedView>
              <UsersDetailsRoutes />
            </SuspensedView>
          }
        />
        {/* All Users Details page Routes end  */}

        {/* All Users table begin */}
        <Route
          path='apps/all-users/*'
          element={
            <SuspensedView>
              <UsersManagement />
            </SuspensedView>
          }
        />

        {/* All Users table end */}

        {/* Feedbacks Route begins */}

        <Route
          path='apps/all-feedbacks/*'
          element={
            <SuspensedView>
              <FeedbackManagement />
            </SuspensedView>
          }
        />

        {/* Feedbacks Route ends */}

        <Route
          path='apps/all-community/*'
          element={
            <SuspensedView>
              <CommunityManagement />
            </SuspensedView>
          }
        />
        {/* Contests multi player detail view page Begins */}
        <Route
          path='apps/contest-management/contests/multi-player-details-page/:id/*'
          element={
            <SuspensedView>
              <MultiPlayerContestDetailsRoutes />
            </SuspensedView>
          }
        />
        {/* Contests multi player detail view page Ends */}

        {/* Contest Details page Routes begin */}
        <Route
          path='apps/contest-management/contests/details-page/:id/:userId'
          element={
            <SuspensedView>
              <ContestDetailsRoutes />
            </SuspensedView>
          }
        />
        {/* Contest Details page Routes end  */}

        {/* Contest table Route begins */}
        <Route
          path='apps/contest-management/*'
          element={
            <SuspensedView>
              <ContestsPage />
            </SuspensedView>
          }
        />
        {/* Contest table Route ends */}

        {/* Ads Details view page begins */}
        <Route
          path='apps/ads-management/ads/details-page/:id/*'
          element={
            <SuspensedView>
              <AdsDetailsRoutes />
            </SuspensedView>
          }
        />
        {/* Ads Details view page ends */}

        {/* Ads Table Route Begins */}
        <Route
          path='apps/ads-management/*'
          element={
            <SuspensedView>
              <AdsRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='apps/ads-management/ads/new-add'
          element={
            <SuspensedView>
              <CreateNewAdd />
            </SuspensedView>
          }
        />
        {/* <Route
          path='apps/ads-management/ads/:id/edit-ad'
          element={
            <SuspensedView>
              <EditAd />
            </SuspensedView>
          }
        /> */}

        {/* Ads Table Route Ends */}

        {/* Leagues detail view page Begins */}
        <Route
          path='apps/leagues-management/leagues/details-page/:id/*'
          element={
            <SuspensedView>
              {/* <LeagueDetailsViewRoutes /> */}
              <LeagueDetailsRoutes />
            </SuspensedView>
          }
        />
        {/* Leagues detail view page Ends */}

        {/* Leagues Table Route Begins */}
        <Route
          path='apps/leagues-management/*'
          element={
            <SuspensedView>
              <LeaguesRoutes />
            </SuspensedView>
          }
        />
        {/* Leagues Table Route Ends */}

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
