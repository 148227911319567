import {useLazyQuery, useMutation} from '@apollo/client'
import {
  DELETE_MANY_TICKETS,
  GET_SUPPORT_TICKETS,
  GET_TICKET_BY_ID,
  UPDATE_TICKET_STATUS,
} from '../queries/ticket.query'
import {stat} from 'fs'

const useTicket = () => {
  const [getTicketsPaginated] = useLazyQuery(GET_SUPPORT_TICKETS)
  const [deleteManyTickets] = useMutation(DELETE_MANY_TICKETS)
  const [getTicketByIDR] = useLazyQuery(GET_TICKET_BY_ID)
  const [updateTicketStatus] = useMutation(UPDATE_TICKET_STATUS)

  function capitalizeFirstLetter(str:string) {
    if (!str) return '' // Handle empty string or null case
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  const getTickets = async (
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    status: string,
    category: string,
    search: string
  ) => {
    try {
      const request = {
        variables: {
          page,
          limit,
          startDate,
          endDate,
          status,
          category,
          search,
        },
      }

      const {data, error} = await getTicketsPaginated(request)

      console.log(error, 'err')
      console.log(data, 'response')
      return {
        data,
        error,
      }
    } catch (error: any) {
      console.log(error, 'error')
      throw new Error(error)
    }
  }

  const deleteTickets = async (ids: String[]) => {
    try {
      const request = {
        variables: {
          ids,
        },
      }
      const {data, errors} = await deleteManyTickets(request)
      return {
        data,
        errors,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const getTicket = async (id: string) => {
    try {
      const request = {
        variables: {
          id,
        },
      }
      const {data, loading, error} = await getTicketByIDR(request)
      return {
        data,
        loading,
        error,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const updateTicket = async (id: string, status: string) => {
    try {
      const request = {
        variables: {
          status,
          id,
        },
      }
      const {data, errors} = await updateTicketStatus(request)
      return {
        data,
        errors,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return {
    getTickets,
    deleteTickets,
    getTicket,
    updateTicket,
  }
}

export default useTicket
