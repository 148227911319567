import React, {createContext, useEffect, useState} from 'react'
import {Socket, io} from 'socket.io-client'
import config from './index'
import useAdmin from '../hooks/useAdmin'

interface ChatSocketContextProps {
  socket: Socket | null
}

const SocketContext = createContext<ChatSocketContextProps>({
  socket: null,
})

const SocketProvider = ({children}: {children: React.ReactNode}) => {
  const [socket, setSocket] = useState<Socket | null>(null)
  const {adminInfo} = useAdmin()

  const initSocket = async () => {
    const {data} = await adminInfo()
    const adminId = data?.admin?._id

    const url = config.SOCKET_URL + `/?sender_id=${adminId}`

    console.log(url, 'url here')
    const socket = io(url, {
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttempts: 10,
      agent: false,
      upgrade: false,
      rejectUnauthorized: false,
    })

    return socket
  }
  useEffect(() => {
    const init = async () => {
      const s = await initSocket()
      setSocket(s)
    }

    init()
  }, [])

  return <SocketContext.Provider value={{socket}}>{children}</SocketContext.Provider>
}
export {SocketContext, SocketProvider}
