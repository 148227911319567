// @ts-nocheck
import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {format} from 'date-fns'

import moment from 'moment'

const AdsDetailsViewOverview = ({post, loading, id}) => {
  const [link, setLink] = useState('')
  useEffect(() => {
    const init = () => {
      var description = post.content
      const linkRegex = /<link>(.*?)<\/link>/
      if (description) {
        const match = description?.match(linkRegex)
        const l = match ? match[1] : null
        setLink(l)
      }
    }

    if (post) {
      init()
    }
  }, [post])
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Ticket Details</h3>
          </div>
        </div>

        {/* Full Name */}
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Category </label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{post?.category ? post?.category : 'loading'}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Subject</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>
                {post?.subject ? post?.subject : 'Loading'}
              </span>
            </div>
          </div>

          {/* Contact */}
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Description</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>
                <div
                  dangerouslySetInnerHTML={{__html: post?.content ? post?.content : 'Loading'}}
                  className='html-content text-wrap'
                  style={{width: '400px'}}
                />
              </span>
            </div>
          </div>

          {/* Gender */}
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Ticket Id</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                {post?.supportTicketID ? post?.supportTicketID : 'loading'}
              </span>
            </div>
          </div>

          {/* Start Time */}
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Created at</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                {post?.createdAt ? moment(new Date(post?.createdAt)).format('LL') : 'Loading'}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          {/* <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        </div>

        <div className='col-xl-6'>
          {/* <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          {/* <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        </div>

        <div className='col-xl-6'>
          {/* <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        </div>
      </div>
    </>
  )
}

export default AdsDetailsViewOverview
