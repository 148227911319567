// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import UserPhoneNumberCell from './UserPhoneNumberCell'
import UserGenderCell from './UserGenderCell'
import UserLocationCell from './UserLocationCell'
import {UserStatusCell} from './UserStatusCell'
import {UserActionsCell} from './UserActionsCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: '_id',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index]._id} />,
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Phone' className='min-w-125px' />
    ),
    id: 'phone',
    Cell: ({...props}) => <UserPhoneNumberCell phone={props.data[props.row.index].phone_number} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Gender' className='min-w-125px' />
    ),
    id: 'gender',
    accessor: 'gender',
    Cell: ({...props}) => <UserGenderCell gender={props.data[props.row.index].gender} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Subscription' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => (
      <UserTwoStepsCell two_steps={props.data[props.row.index].subscriptionType} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <UserStatusCell isBlocked={props.data[props.row.index].isBlocked} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Last Seen' className='min-w-125px' />
    ),
    id: 'Last seen',
    Cell: ({...props}) => <UserLocationCell phone={props.data[props.row.index].last_seen} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {usersColumns}
