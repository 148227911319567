// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import UserPhoneNumberCell from './UserPhoneNumberCell'
import UserGenderCell from './UserGenderCell'
import UserLocationCell from './UserLocationCell'
import {UserStatusCell} from './UserStatusCell'
import {UserActionsCell} from './UserActionsCell'
import moment from 'moment'
import useTicket from '../../../../../hooks/useTicket'
import {capitalizeFirstLetter} from '../../../../../helper/generalHelpers'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: '_id',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index]._id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].user} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Ticket Id' className='min-w-125px' />
    ),
    accessor: 'ticket_id',
    Cell: ({...props}) => {
      var ticketId = props.data[props.row.index].supportTicketID

      return <div>{ticketId}</div>
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Subject' className='min-w-125px' />
    ),
    accessor: 'subject',
    Cell: ({...props}) => {
      var subject = props.data[props.row.index].subject

      return <div>{subject}</div>
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Category' className='min-w-125px' />
    ),
    accessor: 'category',
    Cell: ({...props}) => {
      var category = props.data[props.row.index].category

      return <div>{category}</div>
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status',
    Cell: ({...props}) => {
      const getBadgeColor = (status) => {
        switch (status) {
          case 'accepted':
            return 'success'
          case 'inprogress':
            return 'warning'
          case 'rejected':
            return 'danger'
          case 'completed':
            return 'primary'
          default:
            return 'secondary'
        }
      }

      var status = props.data[props.row.index].status
      return (
        <div className={`badge bg-${getBadgeColor(status)}`}>{capitalizeFirstLetter(status)}</div>
      )
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Created Date' className='min-w-125px' />
    ),
    accessor: 'createdAt',
    Cell: ({...props}) => {
      var created_date = new Date(props.data[props.row.index].createdAt)

      return <div>{moment(created_date).format('LL')}</div>
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {usersColumns}
