import {useLazyQuery, useMutation} from '@apollo/client'
import {
  DELETE_COMMUNITIES,
  GET_COMMUNITIES,
  GET_COMMUNITY,
  GET_USER_POSTS,
  UPDATE_COMMUNITY_STATUS,
  UPDATE_POSTS_ABILITY,
} from '../queries/community.query'

const useCommunity = () => {
  const [getCommunities] = useLazyQuery(GET_COMMUNITIES)
  const [deleteManyPosts] = useMutation(DELETE_COMMUNITIES)
  const [getCommunity] = useLazyQuery(GET_COMMUNITY)
  const [updateCommunityStatusR] = useMutation(UPDATE_COMMUNITY_STATUS)
  const [updateUserPostAbility] = useMutation(UPDATE_POSTS_ABILITY)
  const [getUserCommunities] = useLazyQuery(GET_USER_POSTS)

  const getPosts = async (
    page: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    searchString: string
  ) => {
    try {
      const request = {
        variables: {
          page,
          pageSize,
          startDate,
          endDate,
          searchString,
        },
      }

      const {data, error} = await getCommunities(request)

      console.log(error, 'err')
      console.log(data, 'response')
      return {
        data,
        error,
      }
    } catch (error: any) {
      console.log(error, 'error')
      throw new Error(error)
    }
  }

  const getUserPosts = async (userId: string) => {
    try {
      const request = {
        variables: {
          getUserCommunitiesId: userId,
        },
      }
      const {data, error} = await getUserCommunities(request)
      return {
        data,
        error,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }
  const deleteManyPostsByID = async (ids: string[]) => {
    try {
      const request = {
        variables: {
          ids,
        },
      }
      const {data, errors} = await deleteManyPosts(request)
      return {
        data,
        errors,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const getPost = async (id: string) => {
    try {
      const request = {
        variables: {
          getCommunityId: id,
        },
      }
      const {data, error} = await getCommunity(request)
      return {
        data,
        error,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const updateCommunityStatus = async (id: string, status: string) => {
    try {
      const request = {
        variables: {
          updateCommunityStatusRId: id,
          status,
        },
      }
      const {data, errors} = await updateCommunityStatusR(request)
      return {data, errors}
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const updatePostAbility = async (userId: string, canPost: boolean) => {
    try {
      const request = {
        variables: {
          userId,
          canPost,
        },
      }

      const {data, errors} = await updateUserPostAbility(request)

      console.log(data, 'data')
      console.log(errors, 'errors')
      return {data, errors}
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return {
    getPosts,
    deleteManyPostsByID,
    getPost,
    updateCommunityStatus,
    updatePostAbility,
    getUserPosts,
  }
}

export default useCommunity
