import {gql} from '@apollo/client'

export const GET_COMMUNITIES = gql`
  query GetCommunities(
    $page: Int
    $pageSize: Int
    $startDate: Date
    $endDate: Date
    $searchString: String
  ) {
    getCommunities(
      page: $page
      pageSize: $pageSize
      startDate: $startDate
      endDate: $endDate
      searchString: $searchString
    ) {
      docs {
        _id
        posted_by {
          _id
          first_name
          last_name
          email
          phone_number
          profile_picture
          date_of_birth
          gender
          isBlocked
          last_seen
          subscriptionType
        }
        content
        likesCount
        sharesCount

        status
        type
        images
        createdAt
      }
      totalDocs
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      prevPage
      nextPage
    }
  }
`

export const DELETE_COMMUNITIES = gql`
  mutation DeleteManyPosts($ids: [String!]!) {
    deleteManyPosts(ids: $ids) {
      status
      message
    }
  }
`

export const GET_COMMUNITY = gql`
  query GetCommunity($getCommunityId: String!) {
    getCommunity(id: $getCommunityId) {
      status
      message
      community {
        _id
        posted_by {
          _id
          first_name
          last_name
          email
          phone_number
          profile_picture
          date_of_birth
          gender
          isBlocked
          last_seen
          subscriptionType
        }
        content
        likesCount
        sharesCount

        status
        type
        images
        createdAt
      }
    }
  }
`

export const UPDATE_COMMUNITY_STATUS = gql`
  mutation UpdateCommunityStatusR($updateCommunityStatusRId: String!, $status: statusTypes!) {
    updateCommunityStatusR(id: $updateCommunityStatusRId, status: $status) {
      status
      message
    }
  }
`

export const UPDATE_POSTS_ABILITY = gql`
  mutation UpdateUserPostAbility($userId: String!, $canPost: Boolean!) {
    updateUserPostAbility(userId: $userId, canPost: $canPost) {
      status
      message
    }
  }
`

export const GET_USER_POSTS = gql`
  query GetUserCommunities($getUserCommunitiesId: String!) {
    getUserCommunities(id: $getUserCommunitiesId) {
      status
      message
      communities {
        _id
        posted_by {
          _id
          first_name
          last_name
          email
          phone_number
          profile_picture
          date_of_birth
          gender
          isBlocked
          last_seen
          can_post
          subscriptionType
        }
        content
        likesCount
        sharesCount

        status
        type
        images
        createdAt
      }
    }
  }
`
