import moment from 'moment'
import React, {FC} from 'react'

type Props = {
  phone?: string
}

const UserLocationCell: FC<Props> = ({phone}) => {
  return (
    <div className='badge badge-light fw-bolder'>{phone ? moment(phone).format('LL') : 'N/A'}</div>
  )
}

export default UserLocationCell
