import React, {useState} from 'react'

interface Post {
  videos: string[]
}

interface Props {
  post: Post
}

const PostVideosView: React.FC<Props> = ({post}) => {
  const [selectedVideo, setSelectedVideo] = useState<number>(0)

  if (!post?.videos?.length) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Ticket Videos</h3>
          </div>
        </div>
        <div className='card-body pt-9 pb-9 text-center'>
          <div className='text-muted fs-6'>No videos available</div>
        </div>
      </div>
    )
  }

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ticket Videos</h3>
        </div>
      </div>

      <div className='card-body pt-5 pb-5'>
        {/* Main Video Player */}
        <div className='position-relative mb-5'>
          <video
            key={selectedVideo}
            src={post.videos[selectedVideo]}
            className='w-100 rounded'
            style={{maxHeight: '70vh'}}
            controls
            controlsList='nodownload'
            playsInline
          />
        </div>

        {/* Video Thumbnails */}
        {post.videos.length > 1 && (
          <div className='row g-3'>
            {post.videos.map((video, index) => (
              <div key={index} className='col-6 col-sm-4 col-md-3 col-lg-2'>
                <div
                  className={`position-relative video-thumbnail cursor-pointer ${
                    selectedVideo === index ? 'border border-primary border-3' : ''
                  }`}
                  onClick={() => setSelectedVideo(index)}
                >
                  <video
                    src={video[index]}
                    className='w-100 rounded'
                    style={{height: '100px', objectFit: 'cover'}}
                  />
                  <div
                    className={`position-absolute top-0 start-0 w-100 h-100 rounded ${
                      selectedVideo === index
                        ? 'bg-primary bg-opacity-10'
                        : 'bg-dark bg-opacity-25 hover-overlay'
                    }`}
                  />
                  <div className='position-absolute top-50 start-50 translate-middle'>
                    <span className='badge bg-dark bg-opacity-75'></span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <style>
        {`
          .video-thumbnail {
            transition: transform 0.2s ease-in-out;
          }
          
          .video-thumbnail:hover {
            transform: scale(1.05);
          }
          
          .hover-overlay:hover {
            background-color: rgba(0, 0, 0, 0.1) !important;
          }
          
          .cursor-pointer {
            cursor: pointer;
          }
        `}
      </style>
    </div>
  )
}

export default PostVideosView
