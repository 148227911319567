import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import SubAdminDetails from '../SubAdmins/SubAdminDetails/SubAdminDetails'
import {UsersListWrapper} from './users-list/UsersList'
import UsersDetailsPage from './UsersDetailsPage'
import AdsDetailsViewPage from './AdsDetailsViewPage'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Community Management',
    path: '/apps/all-community/community',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersManagement = () => {
  return (
    <Routes>
      {/* <Route element={<Outlet />}> */}
      <Route
        path='community'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Community Posts</PageTitle>
            <UsersListWrapper />
          </>
        }
      />
      <Route path='community/details-page/:id/*' element={<AdsDetailsViewPage />} />

      {/* </Route> */}
      <Route element={<Navigate to='/apps/all-community/community' />} />
    </Routes>
  )
}

export default UsersManagement
