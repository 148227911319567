/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState, Fragment} from 'react'
import clsx from 'clsx'
import {Dialog, Transition, TransitionChild, DialogPanel} from '@headlessui/react'
import {X} from 'lucide-react'
import {SocketContext} from '../../config/socket.init'
import {useSelector} from 'react-redux'
import useAdmin from '../../hooks/useAdmin'
import {uploadDirectOnS3, uploadDirectOnS3Progress} from '../../helper/s3Helper'
import MediaViewer from './MediaViewer'

type Props = {
  isDrawer?: boolean
  post: any
  id: any
  supportTicketID: String
  userId: String
}

interface UserInfoModelR {
  id: string
  initials?: {label: string; state: 'warning' | 'danger' | 'primary' | 'success' | 'info'}
  name: string
  avatar?: string
  email: string
  position: string
  online: boolean
}

interface MessageModelR {
  id?: String
  user?: string
  type?: 'in' | 'out'
  images: string[] | []
  videos: string[] | []
  createdAt?: ''
  text: string
  time?: string
  template?: boolean
}

interface UploadingFile {
  file: File
  progress: number
  preview: string
  status: 'uploading' | 'completed' | 'error'
}

//@ts-ignore
let bufferMessages: MessageModelR[] = []

const SupportChat: FC<Props> = ({isDrawer = false, post, id, supportTicketID, userId}) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<MessageModelR[]>([])
  const [userInfos, setUserInfos] = useState<UserInfoModelR[]>([])
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFile[]>([])
  const [admin, setAdmin] = useState({})
  const adminId = useSelector((state: any) => state.general.adminId)

  const {socket} = useContext(SocketContext)
  const {adminInfo} = useAdmin()

  useEffect(() => {
    console.log('socket', socket)
    if (socket) {
      console.log('Socket is ONNN')
      console.log('Socket connected:', socket.connected)

      socket.on('connect', () => {
        console.log('Socket connected successfully')
      })

      socket.emit('support-ticket-get-messages', {
        supportTicketID,
        //@ts-ignore
        userId: adminId,
      })

      console.log('Received Message #########')

      socket.on('support-ticket-get-messages-response', (data) => {
        console.log('Received messages response:', data)
        const {messages} = data
        const newMessagesArray = messages.map((message: any) => {
          return {
            id: message?.sentBy === 'admin' ? message?.admin._id : message.user?._id,
            user: message?.sentBy === 'admin' ? message?.admin._id : message.user?._id,
            type: message.sentBy === 'admin' ? 'out' : 'in',
            text: message.text,
            images: message.images ? message.images : [],
            videos: message.videos ? message.videos : [],
            time: message.time,
            createdAt: message.createdAt,
          }
        })

        const newUserInfos = messages.map((message: any) => {
          return {
            id: message?.sentBy === 'admin' ? message?.admin._id : message.user?._id,
            name:
              message?.sentBy === 'admin'
                ? message?.admin.name
                : message.user.first_name + ' ' + message.user.last_name,
            avatar:
              message?.sentBy === 'admin'
                ? 'https://i.pinimg.com/736x/69/a4/d9/69a4d9afcec8aefef5e1430f5587c273.jpg'
                : message.user.profile_picture,
            email: message?.sentBy === 'admin' ? message?.admin.email : message.user.email,
            position: message?.sentBy === 'admin' ? message?.admin.position : message.user.position,
            online: true,
          }
        })

        console.log('User Infos: ', newUserInfos)
        const mesRev = newMessagesArray.reverse()
        bufferMessages = mesRev
        setMessages(bufferMessages)
        setUserInfos(newUserInfos)
      })

      socket.emit('support-ticket-received-messages', {
        supportTicketID: supportTicketID,
        //@ts-ignore
        sentBy: 'user',
      })

      socket.on('support-ticket-receive-message', (data) => {
        console.log('Received message Live Update:', data)
        const newMessage = {
          text: data.text,
          id: userId,
          template: false,
          time: data?.createdAt,
          images: data?.images ? data?.images : [],
          videos: data?.videos ? data?.videos : [],
          type: 'in' as 'in',
          createdAt: data?.createdAt,
        }
        bufferMessages.push(newMessage)
        setMessages(bufferMessages)
        toggleChatUpdateFlat(!chatUpdateFlag)
      })
    }
    return () => {
      socket?.off('support-ticket-get-messages-response')
      socket?.off('support-ticket-receive-message')
    }
  }, [])

  useEffect(() => {
    const init = async () => {
      try {
        const {data, loading, error} = await adminInfo()
        setAdmin(data?.admin)
        setUserInfos((pv) => [
          ...pv,
          {
            id: data?.admin?._id,
            name: data?.admin?.name,
            avatar: 'https://i.pinimg.com/736x/69/a4/d9/69a4d9afcec8aefef5e1430f5587c273.jpg',
            email: data?.admin?.email,
            position: 'Admin',
            online: true,
          },
        ])
      } catch (error) {
        console.log(error)
      }
    }
    init()
  }, [])

  const onSendMessage = async (text: string, imagesLink: string[], videosLink: string[]) => {
    if (socket) {
      socket.emit('support-ticket-send-message', {
        userId: post?.user?._id,
        //@ts-ignore
        adminId: admin?._id,
        text,
        images: imagesLink,
        videos: videosLink,
        supportTicketID: id,
        sentBy: 'admin',
      })
    }
    setSelectedFiles([])
  }

  const uploadFiles = async () => {
    const images = selectedFiles.filter((file) => file.type.startsWith('image/'))
    const videos = selectedFiles.filter((file) => file.type.startsWith('video/'))

    let imagesLink: string[] = []
    let videosLink: string[] = []

    try {
      if (images.length) {
        await Promise.all(
          images.map(async (image, index) => {
            const updateProgress = (progress: number) => {
              setUploadingFiles((prev) =>
                prev.map((file) => (file.file === image ? {...file, progress} : file))
              )
            }

            const newImageLink = await uploadDirectOnS3Progress({
              file: image,
              title: image.name,
              type: 'images',
              onProgress: updateProgress,
            })

            setUploadingFiles((prev) =>
              prev.map((file) => (file.file === image ? {...file, status: 'completed'} : file))
            )

            imagesLink.push(newImageLink)
          })
        )
      }

      if (videos.length) {
        await Promise.all(
          videos.map(async (video) => {
            const updateProgress = (progress: number) => {
              setUploadingFiles((prev) =>
                prev.map((file) => (file.file === video ? {...file, progress} : file))
              )
            }

            const newVideoLink = await uploadDirectOnS3Progress({
              file: video,
              title: video.name,
              type: 'videos',
              onProgress: updateProgress,
            })

            setUploadingFiles((prev) =>
              prev.map((file) => (file.file === video ? {...file, status: 'completed'} : file))
            )

            videosLink.push(newVideoLink)
          })
        )
      }

      await onSendMessage(message, imagesLink, videosLink)
      setUploadingFiles([])
      setSelectedFiles([])
      setMessage('')

      const newMessage: MessageModelR = {
        //@ts-ignore
        id: admin?._id,
        //@ts-ignore
        user: admin?._id,
        images: imagesLink,
        videos: videosLink,
        type: 'out',
        text: message,
        time: 'Just now',
        //@ts-ignore
        createdAt: new Date().toString(),
      }

      bufferMessages.push(newMessage)
      setMessages(bufferMessages)
      toggleChatUpdateFlat(!chatUpdateFlag)
    } catch (error) {
      console.error('Upload failed:', error)
      setUploadingFiles((prev) => prev.map((file) => ({...file, status: 'error'})))
    }
  }

  const sendMessage = () => {
    if (selectedFiles.length > 0) {
      uploadFiles()
    } else if (message.trim()) {
      onSendMessage(message, [], [])
      const newMessage: MessageModelR = {
        //@ts-ignore
        id: admin?._id,
        //@ts-ignore
        user: admin?._id,
        images: [],
        videos: [],
        type: 'out',
        text: message,
        time: 'Just now',
        //@ts-ignore
        createdAt: new Date().toString(),
      }

      bufferMessages.push(newMessage)
      setMessages(bufferMessages)
      toggleChatUpdateFlat(!chatUpdateFlag)
      setMessage('')
    }
  }

  const handleFileChange = (event: any) => {
    const files: File[] = Array.from(event.target.files)
    if (files) {
      const newUploadingFiles: UploadingFile[] = files.map((file) => ({
        file,
        progress: 0,
        preview: URL.createObjectURL(file),
        status: 'uploading',
      }))
      setUploadingFiles((prev) => [...prev, ...newUploadingFiles])
      setSelectedFiles((prev) => [...prev, ...files])
    }
  }

  const removeFile = (index: number) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index))
    setUploadingFiles((prev) => prev.filter((_, i) => i !== index))
  }

  const renderUploadingFile = (file: UploadingFile, index: number) => {
    return (
      <div className='position-relative'>
        {file.file.type.startsWith('image/') ? (
          <img
            src={file.preview}
            alt='Uploading'
            className='img-thumbnail'
            style={{width: '100px', height: '100px', objectFit: 'cover'}}
          />
        ) : (
          <video
            className='img-thumbnail'
            style={{width: '100px', height: '100px', objectFit: 'cover'}}
          >
            <source src={file.preview} type={file.file.type} />
          </video>
        )}

        {file.status === 'uploading' && (
          <div className='position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-dark bg-opacity-50'>
            <div className='spinner-border text-light' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
            <div className='position-absolute text-white'>{Math.round(file.progress)}%</div>
          </div>
        )}

        {file.status === 'error' && (
          <div className='position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-danger bg-opacity-50'>
            <i className='bi bi-exclamation-triangle-fill text-white'></i>
          </div>
        )}

        <button
          type='button'
          className='btn-close position-absolute top-0 end-0 p-1 bg-dark bg-opacity-50 text-white rounded-circle'
          onClick={() => removeFile(index)}
        ></button>
      </div>
    )
  }

  const [isOpen, setIsOpen] = useState(false)
  const [selectedMedia, setSelectedMedia] = useState({type: '', url: ''})
  const [isMediaViewerOpen, setIsMediaViewerOpen] = useState(false)
  const [selectedMediaArray, setSelectedMediaArray] = useState<{type: string; url: string}[]>([])
  const openMediaViewer = (type: string, url: string, mediaArray: string[]) => {
    // Convert the media array to the format expected by MediaViewer
    const formattedMedia = mediaArray.map((mediaUrl) => ({
      type,
      url: mediaUrl,
    }))

    setSelectedMediaArray(formattedMedia)
    setIsMediaViewerOpen(true)
  }
  const openModal = (type: any, url: any) => {
    console.log(type, url)
    setSelectedMedia({type, url})
    setIsOpen(true)
    openMediaViewer(type, url, [url])
  }

  const closeModal = () => {
    setIsOpen(false)
    setSelectedMedia({type: '', url: ''})
  }

  const formatMessageTimestamp = (timestamp: string | Date): string => {
    const messageDate = new Date(timestamp)
    const now = new Date()

    const diffInSeconds = Math.floor((now.getTime() - messageDate.getTime()) / 1000)
    const diffInMinutes = Math.floor(diffInSeconds / 60)
    const diffInHours = Math.floor(diffInMinutes / 60)
    const diffInDays = Math.floor(diffInHours / 24)
    const diffInWeeks = Math.floor(diffInDays / 7)

    // Just now
    if (diffInSeconds < 60) {
      return 'Just now'
    }

    // Minutes ago
    if (diffInMinutes < 60) {
      return `${diffInMinutes} min ago`
    }

    // Hours ago
    if (diffInHours < 24) {
      return `${diffInHours} hr ago`
    }

    // Yesterday
    if (diffInDays === 1) {
      return messageDate
        .toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
        .toLowerCase()
    }

    // Weeks ago
    if (diffInDays < 7) {
      return `${diffInDays} days ago`
    }

    if (diffInWeeks < 4) {
      return `${diffInWeeks} weeks ago`
    }

    // For older messages, show date and time
    return messageDate
      .toLocaleString([], {
        year: diffInDays > 365 ? 'numeric' : undefined,
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
      .toLowerCase()
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div
          className='card-body'
          id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
        >
          <div
            className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
            data-kt-element='messages'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies={
              isDrawer
                ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
            }
            data-kt-scroll-wrappers={
              isDrawer
                ? '#kt_drawer_chat_messenger_body'
                : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
            }
            data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
          >
            {messages.map((message, index) => {
              const userInfo = userInfos.find((u) => u.id === message.id)
              const state = message.type === 'in' ? 'info' : 'primary'
              const templateAttr = {}
              if (message.template) {
                Object.defineProperty(templateAttr, 'data-kt-element', {
                  value: `template-${message.type}`,
                })
              }
              const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
                message.type === 'in' ? 'start' : 'end'
              } mb-10`
              return (
                <div
                  key={`message${index}`}
                  className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
                  {...templateAttr}
                >
                  <div
                    className={clsx(
                      'd-flex flex-column align-items',
                      `align-items-${message.type === 'in' ? 'start' : 'end'}`
                    )}
                  >
                    <div className='d-flex align-items-center mb-2'>
                      {message.type === 'in' && userInfo ? (
                        <>
                          <div className='symbol symbol-35px symbol-circle'>
                            <img alt='Pic' src={userInfo.avatar} />
                          </div>
                          <div className='ms-3'>
                            <a
                              href='#'
                              className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                            >
                              {userInfo.name}
                            </a>
                            <span className='text-muted fs-7 mb-1'>{userInfo.position}</span>
                          </div>
                        </>
                      ) : null}
                      {message.type === 'out' ? (
                        <>
                          <div className='me-3'>
                            <span className='text-muted fs-7 mb-1'>{userInfo?.position}</span>
                            <a
                              href='#'
                              className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                            >
                              {'Customer Support'}
                            </a>
                          </div>
                          <div className='symbol symbol-35px symbol-circle'>
                            <img alt='Pic' src={userInfo?.avatar} />
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div
                      className={clsx(
                        'p-5 rounded',
                        `bg-light-${state} text-dark fw-bold mw-lg-400px text-start`,
                        {'bg-light-info': message.type === 'in'}
                      )}
                      data-kt-element='message-text'
                    >
                      <div
                        className={clsx(
                          'text-muted fs-7',
                          `text-${message.type === 'in' ? 'start' : 'end'}`
                        )}
                      >
                        {formatMessageTimestamp(message.createdAt as string)}
                      </div>
                      {message.text}
                    </div>
                    {message.images.length > 0 && (
                      <div className='d-flex justify-content-center mt-5'>
                        {message.images.map((image, index) => (
                          <img
                            key={`image${index}`}
                            src={image}
                            alt={`Image ${index}`}
                            className='mw-200px mh-200px mx-4 cursor-pointer'
                            onClick={() => openModal('image', image)}
                          />
                        ))}
                      </div>
                    )}
                    {message.videos.length > 0 && (
                      <div className='d-flex justify-content-center mt-5'>
                        {message.videos.map((video, index) => (
                          <video
                            key={`video${index}`}
                            src={video}
                            controls
                            className='mw-200px mh-200px mx-4 cursor-pointer'
                            onClick={() => openModal('video', video)}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <form className='card-footer pt-4' id='kt_drawer_chat_messenger_footer'>
          <div className='d-flex flex-wrap mb-3 gap-2'>
            {uploadingFiles.map((file, index) => (
              <div key={index}>{renderUploadingFile(file, index)}</div>
            ))}
          </div>

          <textarea
            className='form-control form-control-flush mb-3'
            rows={1}
            placeholder='Type a message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className='d-flex justify-content-between'>
            <label className='btn btn-sm btn-outline-secondary'>
              <i className='bi bi-paperclip fs-5'></i>
              <input
                type='file'
                multiple
                accept='image/*,video/*'
                className='d-none'
                onChange={handleFileChange}
              />
            </label>
            <button className='btn btn-primary' type='button' onClick={sendMessage}>
              Send
            </button>
          </div>
        </form>
      </div>

      {/* Replace the existing modal with MediaViewer */}
      <MediaViewer
        media={selectedMediaArray}
        isOpen={isMediaViewerOpen}
        onClose={() => setIsMediaViewerOpen(false)}
      />

      <style>
        {`
          .media-thumbnail {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 8px;
          }
          .video-play-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.3);
            color: white;
            border-radius: 8px;
          }
          .cursor-pointer {
            cursor: pointer;
          }
        `}
      </style>
    </>
  )
}

export {SupportChat}
