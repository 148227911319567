import React, {useState} from 'react'
import {isImageUrl} from '../../helper/imageHelpers'

interface Post {
  images: string[]
}

interface Props {
  post: Post
}

const PostImagesView: React.FC<Props> = ({post}) => {
  const [selectedImage, setSelectedImage] = useState<number>(0)

  if (!post?.images?.length || !post.images.some((img) => isImageUrl(img))) {
    return (
      <div className='card mb-5'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Ticket Images</h3>
          </div>
        </div>
        <div className='card-body py-5 text-center'>
          <p className='text-muted mb-0'>No images available</p>
        </div>
      </div>
    )
  }

  const validImages = post.images.filter((img) => isImageUrl(img))

  return (
    <div className='card mb-5' id='kt_profile_details_view'>
      {/* Card Header */}
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>Ticket Images</h3>
        </div>
      </div>

      {/* Card Body */}
      <div className='card-body py-4'>
        {/* Main Image Display */}
        <div className='position-relative mb-4'>
          <div className='text-center'>
            <img
              src={validImages[selectedImage]}
              alt={`Image ${selectedImage + 1}`}
              className='img-fluid rounded mw-100'
              style={{maxHeight: '70vh', objectFit: 'contain'}}
            />
          </div>
        </div>

        {/* Image Thumbnails */}
        {validImages.length > 1 && (
          <div className='row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-6 g-3'>
            {validImages.map((image, index) => (
              <div key={index} className='col'>
                <div
                  role='button'
                  onClick={() => setSelectedImage(index)}
                  className={`position-relative h-100 ${
                    selectedImage === index
                      ? 'border border-3 border-primary rounded shadow'
                      : 'border rounded'
                  }`}
                >
                  <div className='ratio ratio-1x1'>
                    <img
                      src={image}
                      alt={`Thumbnail ${index + 1}`}
                      className='rounded object-fit-cover w-100 h-100'
                    />
                    <div
                      className={`position-absolute top-0 start-0 w-100 h-100 ${
                        selectedImage === index
                          ? 'bg-primary bg-opacity-10'
                          : 'bg-dark bg-opacity-25'
                      }`}
                    />
                    <span className='position-absolute top-50 start-50 translate-middle badge bg-dark bg-opacity-75'></span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default PostImagesView
