// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import UserPhoneNumberCell from './UserPhoneNumberCell'
import UserGenderCell from './UserGenderCell'
import UserLocationCell from './UserLocationCell'
import {UserStatusCell} from './UserStatusCell'
import {UserActionsCell} from './UserActionsCell'
import moment from 'moment'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: '_id',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index]._id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].posted_by} />,
  },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Subscription' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].subscription} />,
  // },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Location' className='min-w-125px' />
  //   ),
  //   id: 'location',
  //   Cell: ({...props}) => <UserLocationCell phone={props.data[props.row.index].phone} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Content' className='min-w-125px' />
  //   ),
  //   accessor: 'feedback',
  //   Cell: ({...props}) => {
  //     var description = props.data[props.row.index].content
  //     return (
  //       <div
  //         dangerouslySetInnerHTML={{__html: description}}
  //         className='html-content text-wrap'
  //         style={{width: '400px'}}
  //       />
  //     )
  //   },
  // },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Teaching' className='min-w-125px' />
  //   ),
  //   accessor: 'teaching',
  //   Cell: ({...props}) => {
  //     var description = props.data[props.row.index].content
  //     const linkRegex = /<link>(.*?)<\/link>/
  //     const match = description.match(linkRegex)
  //     const link = match ? match[1] : null
  //     return <div>{link}</div>
  //   },
  // },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Likes' className='min-w-125px' />
    ),
    accessor: 'likes',
    Cell: ({...props}) => {
      var likes = props.data[props.row.index].likesCount

      return <div>{likes}</div>
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Shares' className='min-w-125px' />
    ),
    accessor: 'shares',
    Cell: ({...props}) => {
      var shares = props.data[props.row.index].sharesCount

      return <div>{shares}</div>
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Created Date' className='min-w-125px' />
    ),
    accessor: 'createdAt',
    Cell: ({...props}) => {
      var created_date = new Date(props.data[props.row.index].createdAt)

      return <div>{moment(created_date).format('LL')}</div>
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {usersColumns}
