import {useLazyQuery, useMutation} from '@apollo/client'
import {
  GET_ALL_USERS_DATA,
  GET_USERS_SUBSCRIPTION_HISTORY,
  GET_USER_DEVICES,
  MONTHLY_SUBSCRIPTIONS_ANALYTICS,
  OVERALL_SUBSCRIPTIONS_ANALYTICS,
  OVERALL_USERS_ANALYTICS,
  PLATFORM_ANALYTICS,
} from '../queries/user.query'
import {DELETE_FEEDBACK} from '../mutation/feedback.mutation'

const useUsers = () => {
  // Query Call
  const [overAllRegisteredAnalytics] = useLazyQuery(OVERALL_USERS_ANALYTICS)
  const [overAllSubscriptionsAnalytics] = useLazyQuery(OVERALL_SUBSCRIPTIONS_ANALYTICS)
  const [monthlySubscriptionAnalytics] = useLazyQuery(MONTHLY_SUBSCRIPTIONS_ANALYTICS)
  const [getAllUserData] = useLazyQuery(GET_ALL_USERS_DATA)
  const [getUserSubscriptionHistory] = useLazyQuery(GET_USERS_SUBSCRIPTION_HISTORY)

  const [getUserDevices] = useLazyQuery(GET_USER_DEVICES)
  // Mutation Calls
  const [deleteFeedback] = useMutation(DELETE_FEEDBACK)

  const [getPlatformBasedSubscriptions] = useLazyQuery(PLATFORM_ANALYTICS)

  const getSubscriptionHistory = async (userId: string) => {
    try {
      const request = {
        variables: {
          userId,
        },
      }
      const {data, error, loading} = await getUserSubscriptionHistory(request)
      return {
        data,
        error,
        loading,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }
  const getOverallUserAnalytics = async (fromMonth: string, toMonth: string) => {
    try {
      const request = {
        variables: {
          fromMonth,
          toMonth,
        },
      }
      const {data, error, loading} = await overAllRegisteredAnalytics(request)
      return {
        data,
        error,
        loading,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const getOverAllSubscriptionsAnalytics = async (fromMonth: string, toMonth: string) => {
    try {
      const request = {
        variables: {
          fromMonth,
          toMonth,
        },
      }
      const {data, error, loading} = await overAllSubscriptionsAnalytics(request)
      return {
        data,
        error,
        loading,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const getMonthlySubscriptionsAnalytics = async () => {
    try {
      const {data, error, loading} = await monthlySubscriptionAnalytics()
      return {
        data,
        error,
        loading,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const usersData = async () => {
    try {
      const {data, error, loading} = await getAllUserData()
      return {
        data,
        error,
        loading,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const deleteUserFeedBacks = async (id: string) => {
    try {
      const request = {
        variables: {
          deleteFeedbackId: id,
        },
      }
      const {data, errors} = await deleteFeedback(request)

      return {
        data,
        errors,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const userDevices = async (userId: any) => {
    try {
      const request = {
        variables: {
          userId,
        },
      }
      const {data, error} = await getUserDevices(request)
      return {
        data,
        error,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const getPlatformAnalytics = async (type: String) => {
    try {
      const request = {
        variables: {
          type,
        },
      }
      const {data, loading, error} = await getPlatformBasedSubscriptions(request)

      return {data, loading, error}
    } catch (error: any) {
      console.log(error, 'error here')
      throw new Error(error)
    }
  }
  return {
    getPlatformAnalytics,
    deleteUserFeedBacks,
    getOverallUserAnalytics,
    getOverAllSubscriptionsAnalytics,
    getMonthlySubscriptionsAnalytics,
    usersData,
    getSubscriptionHistory,
    userDevices,
  }
}

export default useUsers
